<template>
  <div 
    v-if="!isEmpty"
    id="base-event-tips"
  >
    <div class="event-tips-section">
      <div class="event-tips-section__item">
        <div class="event-tips-section__title">
          Cek Artikel Tentang COD di Sini Yuk!
        </div>
        <router-link
          v-show="!isMobileSize"
          class="event-tips-section__others"
          to="/info-seller"
        >
          <span style="margin: auto;">Lihat Semua</span>
          <img
            :src="`/images/cod/ics_o_chevron_right.svg`"
            alt="icon chevron lion parcel"
            width="20"
            height="20"
          >
        </router-link>
      </div>
      <div>
        <div
          v-if="!isLoadingFetchData"
          class="animation-fade-in"
        >
          <Carousel
            slider-dot-position="center"
            :name="carouselName"
            :shimmer="false"
            :size="listChunk.length"
            :total-all-data="listData.length"
            :is-absolute="true"
            :show-dot="false"
            margin-slider="55px -35px 0px -35px"
            color-slider="redWithRound"
          >
            <template #item>
              <div
                v-for="(slide, index) in listChunk"
                :id="`${carouselName}-${index + 1}`"
                :key="'item-key-' + index"
                class="slide-number event-tips-section-carousel__wrapper-item"
              >
                <router-link
                  v-for="(val, j) in slide"
                  :id="`id-${carouselName}-${index}-${j}`"
                  :key="`key-${index}-${j}`"
                  class="slide-item event-tips-section-carousel__wrapper-item__child"
                  :to="
                    `/info-${val.article_info.toLowerCase()}/${
                      val.corporate_action_slug
                    }`
                  "
                >
                  <div
                    class="event-tips-section-carousel__wrapper-item__child__image"
                    style="cursor: pointer;"
                  >
                    <ImageLazy
                      :id="`corporation-photo-${j}`"
                      :src="val.attachment_corporate_action_sub_photo_url"
                      :alt="val.alt_sub_photo"
                    />
                    <div
                      class="event-tips-section-carousel__wrapper-item__child__image__desc"
                    >
                      {{ val.corporate_action_title }}
                    </div>
                    <div
                      class="event-tips-section-carousel__wrapper-item__child__image__date"
                    >
                      {{ setTime(val.active_date) }}
                    </div>
                  </div>
                  <div
                    v-show="isMobileSize"
                    class="read-article"
                  >
                    Baca Artikel
                  </div>
                </router-link>
              </div>
            </template>
          </Carousel>
        </div>
        <div
          v-else
          style="text-align: center; display:flex;"
        >
          <div
            v-for="sk in defaultSize"
            v-show="!isMobileSize"
            :key="sk"
            class="skeleton-box event-tips-section-carousel__wrapper-item__child__image"
            style="height: 32vh; width: 20vw; margin-right: 24px; border-radius: 8px; max-width: 292px; max-height: 185px;"
          />
          <div
            v-for="sk in defaultSizeMobile"
            v-show="isMobileSize"
            :key="sk + 'mobile'"
            class="skeleton-box event-tips-section-carousel__wrapper-item__child__image"
            style="height: 14vh; width: 50vw; margin-right: 10px; border-radius: 8px; max-width: 162px; max-height: 96px;"
          />
        </div>
      </div>
      <router-link
        v-show="isMobileSize"
        class="event-tips-section__others"
        to="/info-seller"
      >
        <span>Lihat Semua</span>
        <img
          :src="`images/cod/ics_o_chevron_right.svg`"
          alt="icon chevron lion parcel"
          width="20"
          height="20"
        >
      </router-link>
    </div>
  </div>
</template>

<script>
import Carousel from "@/components/new-carousel/Carousel";
import mixinMobile from "@/misc/mixinMobile";
import { chunkArray } from "@/components/new-carousel/util";
import service from "../../new-event-and-tips/app/usecase/index";
import ImageLazy from "@/components/ImageLazy/index.vue";
import { TimeMapper } from "@/misc/timeMapper";

export default {
  name: "EventAndTipsSectionCOD",
  components: { Carousel, ImageLazy },
  mixins: [mixinMobile],
  data() {
    return {
      isLoadingFetchData: false,
      defaultSize: 4,
      defaultSizeMobile: 2,
      carouselName: "event-tips-section-cod",
      listData: [],
      isEmpty: false,
    };
  },
  computed: {
    listChunk() {
      if (this.isMobileSize) {
        return chunkArray(this.listData, 1);
      }
      return chunkArray(this.listData, 4);
    },
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    setTime(date) {
      return TimeMapper.SetFormatDate(
        date,
        true,
        [" ", " ", "-", ":"],
        false,
        false,
        false
      );
    },
    async fetchData() {
      this.isLoadingFetchData = true;
      const data = await service.getAllProgram(1, 16, "", "SELLER", "", "cod", false);     
      if (!data.err) {
        setTimeout(() => {
          this.listData = data.data.data.slice(0, 16);
          this.isLoadingFetchData = false;
          if (this.listData < 1) this.isEmpty = true;
        }, 1000);
      } else {
        this.isLoadingFetchData = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/style";
@import "@/assets/css/animation";

@mixin typography($size, $color, $line-height, $font-weight) {
  font-size: $size;
  color: $color;
  line-height: $line-height;
  font-weight: $font-weight;
}

#base-event-tips {
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 20px;
  max-width: 1280px;
  padding-bottom: 4rem;

  @media (max-width: 1280px) {
    max-width: unset;
    padding: 0 padding-x(1.5);
    padding-bottom: 4rem;
  }

  @include for-size(mobile) {
    display: block;
    padding: 32px 0 32px 24px;
    margin: unset;
    max-width: unset;
  }

  @include for-size(tablet-portrait) {
    height: auto;
    margin: unset;
    max-width: unset;
    padding: 32px 24px;
  }
}

%img {
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  @include for-size(mobile) {
    border-radius: 5px;
  }
}

.event-tips-section {
  display: flex;
  flex-direction: column;
  padding: 0 padding-x(1);
  min-width: 800px;
  gap: 24px;

  @include for-size(mobile) {
    padding: 0 0 10px 0;
    min-width: unset;
  }

  @include for-size(tablet-portrait) {
    padding: 0 10px;
    min-width: unset;
  }

  &__title {
    font-weight: 700;
    font-size: 34px;
    color: $color-gray-shades-6;
    margin: auto auto auto 0;
    letter-spacing: -0.02em;

    @include for-size(mobile) {
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      padding-right: 24px;
    }

    @include for-size(tablet-portrait) {
      font-size: 30px;
      text-align: center;
    }
  }

  &__others {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: $color-base;

    @include for-size(mobile) {
      justify-content: center;
    }

    @include for-size(tablet-portrait) {
      justify-content: center;
    }

    span {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;

      @include for-size(mobile) {
        font-weight: 700;
        font-size: 12px;
      }

      @include for-size(tablet-portrait) {
        font-weight: 700;
        font-size: 14px;
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include for-size(mobile) {
      padding: 0;
    }

    @include for-size(tablet-portrait) {
      display: block;
      padding: 0;
    }
  }
}

.event-tips-section-carousel__wrapper-item {
  &__child {
    @include for-size(mobile) {
      min-height: 242px;
    }

    @include for-size(tablet-portrait) {
      min-height: 350px;
    }

    &__image {
      max-width: 292px;
      width: 100%;
      overflow: hidden;
      position: relative;
      border-radius: 5px;
      text-align: left;

      @include for-size(mobile) {
        max-width: 162px;
      }

      > div:first-child {
        width: 100%;
        margin-bottom: 14px;  

        @include for-size(mobile) {
          margin-bottom: 12px;          
        }

        &::v-deep img {
          object-fit: none;
          width: 292px;
          height: 168px;
          @extend %img;

          @include for-size(mobile) {
            object-fit: cover;
            width: 162px;
            height: 96px;
          }
        }
      }

      &__desc {
        width: 100%;
        border-top: 1px solid $color-white-dark;
        padding-top: 14px;
        padding-bottom: 8px;
        @include typography(16px, $color-gray-shades-6, 24px, 600);        

        @include for-size(mobile) {
          @include typography(12px, $color-gray-shades-6, 14px, 500);
        }
      }

      &__date {
        @include typography(12px, $color-gray-shade, 18px, 500);
      }
    }
  }
}

.slides {
  display: unset;
}

.slide-item {
  margin: 0 12px;

  @include for-size(mobile) {
    margin: 0 6px;
  }
}

.slide-item:first-of-type {
  margin-left: 0;
}

.slide-item:last-of-type {
  margin-right: 0;
}

.slide-number {
  display: inline-block;
  vertical-align: middle;
  margin: 0 12px;

  @include for-size(mobile) {
    margin: 0 6px;
  }
}

.slide-number:first-of-type {
  margin-left: 0;
}

.slide-number:last-of-type {
  margin-right: 0;
}

::v-deep .body-slider {
  align-items: unset !important;
}

.read-article {
  margin-top: 12px;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  color: $color-base;
  width: auto;

  @include for-size(tablet-portrait) {
    text-align: start;
  }
}
</style>
