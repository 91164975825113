<template>
  <div class="cod-section">
    <BreadCrumb class="top-left" />

    <!-- Banner Header -->
    <div class="cod-section__banner-header">
      <ImageLazy
        :src="imgSrcBannerHeader"
        alt="cod_banner_header.png"
        @error="(event) => isErrorImage(event, 'cod-banner')"
      />
    </div>
    <!-- Banner Header -->

    <!-- COD Sub -->
    <div class="cod-section__sub">
      <div class="cod-section__sub__content do-flex-col">
        <div class="cod-section__sub__content__title">
          COD Lion Parcel, Apaan Tuh?
        </div>
        <div class="cod-section__sub__content__desc">
          COD (Cash on Delivery) adalah metode pembayaran di mana penerima membayar ongkos kirim/harga barang kepada kurir saat paket diterima.
        </div>
      </div>
    </div>
    <!-- COD Sub -->

    <!-- Cards -->
    <div class="cod-section__cards">
      <div class="cod-section__cards__card">
        <div class="cod-section__cards__card__bangs" />
        <div class="cod-section__cards__card__content">
          <div class="cod-section__cards__card__content__title">
            <ImageLazy
              :src="`/images/cod/ill_badge_DFOD.svg`"
              alt="dfod_card.png"
              class="cod-section__cards__card__content__title__img"
              @error="(event) => isErrorImage(event, 'dfod')"
            />
            <div class="cod-section__cards__card__content__title__wrapper">
              <div class="cod-section__cards__card__content__title__wrapper__text">
                COD Ongkir
              </div>
              <div class="cod-section__cards__card__content__title__wrapper__badge">
                Baru
              </div>
            </div>
          </div>
          <div class="cod-section__cards__card__content__desc">
            Penerima hanya membayar biaya ongkos kirim (ongkir).
          </div>
        </div>
      </div>
      <div class="cod-section__cards__card">
        <div class="cod-section__cards__card__bangs" />
        <div class="cod-section__cards__card__content">
          <div class="cod-section__cards__card__content__title">
            <ImageLazy
              :src="`/images/cod/ill_badge_COD.svg`"
              alt="cod_card.png"
              class="cod-section__cards__card__content__title__img"
              @error="(event) => isErrorImage(event, 'cod')"
            />
            <div class="cod-section__cards__card__content__title__wrapper">
              <div class="cod-section__cards__card__content__title__wrapper__text">
                COD Ongkir & Barang
              </div>
            </div>
          </div>
          <div class="cod-section__cards__card__content__desc">
            Penerima membayar biaya ongkos kirim (ongkir) & harga barang.
          </div>
        </div>
      </div>
    </div>
    <!-- Cards -->

    <!-- Information -->
    <div class="cod-section__information">
      <div class="cod-section__information__left">
        <div class="cod-section__information__left__title">
          Ini Nih Alasan Kamu Harus Pilih COD di Lion Parcel!
        </div>
        <div class="cod-section__information__left__desc">
          Pastinya membuat bisnis kamu makin laris!
        </div>
      </div>
      <div
        v-show="!isMobileSize"
        class="cod-section__information__right"
      >
        <div
          v-for="(infos, i) of informationGrouping"
          :key="i"
          class="cod-section__information__right__box"
        >
          <div
            v-for="(info, index) of infos"
            :key="index"
            :class="
              `cod-section__information__right__box__box${i + (index + i) + 1}`
            "
          >
            <div class="cod-section__information__right__box__bg-img">
              <ImageLazy
                :src="getImage(info.img)"
                :alt="`box${i + (index + i) + 1}.png`"
                class="cod-section__information__right__box__bg-img__img"
                @error="
                  (event) => isErrorImage(event, `box${i + (index + i) + 1}`)
                "
              />
            </div>
            <div class="cod-section__information__right__box__title">
              {{ info.title }}
            </div>
            <div class="cod-section__information__right__box__desc">
              {{ info.desc }}
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="isMobileSize"
        class="cod-section__information__right-mobile"
      >
        <div
          v-for="(info, index) of LIST_CONTENT.INFORMATION"
          :key="index"
          :class="`cod-section__information__right-mobile__box${index + 1}`"
        >
          <div class="cod-section__information__right-mobile__bg-img">
            <ImageLazy
              :src="getImage(info.img)"
              :alt="`box${index + 1}.png`"
              class="cod-section__information__right-mobile__bg-img__img"
              @error="(event) => isErrorImage(event, `box${index + 1}`)"
            />
          </div>
          <div class="cod-section__information__right-mobile__title">
            {{ info.title }}
          </div>
          <div class="cod-section__information__right-mobile__desc">
            {{ info.desc }}
          </div>
        </div>
      </div>
    </div>
    <!-- Information -->

    <!-- stars -->
    <div class="cod-section__stars">
      <ImageLazy
        v-for="i in 5"
        :key="i"
        :src="`/images/cod/ics_f_star.svg`"
        alt="star.png"
        style="position: relative;"
        class="cod-section__stars__star"
        @error="(event) => isErrorImage(event, 'star')"
      />
    </div>
    <!-- stars -->

    <!-- Profit List -->
    <listPoint
      :contents-list="LIST_CONTENT.PROFIT"
      :title="'Udah Gitu, COD Lion Parcel Untung Banget!'"
    />
    <!-- Profit List -->

    <!-- Flow -->
    <div class="cod-section__flow">
      <div class="cod-section__flow__head">
        <div class="cod-section__flow__head__title">
          Gampang Lagi Tutorialnya, Gak Ribet Deh!
        </div>
        <div class="cod-section__flow__head__desc">
          Cek yuk, sistem alur COD di Agen atau Aplikasi Lion Parcel!
        </div>
        <div
          id="button-tab"
          class="cod-section__flow__head__btn-tab"
        >
          <div
            id="btn-flow-agent"
            class="cod-section__flow__head__btn-tab__button active"
            @click="changeFlow('agent')"
          >
            Agen
          </div>
          <div
            id="btn-flow-app"
            class="cod-section__flow__head__btn-tab__button"
            @click="changeFlow('app')"
          >
            Aplikasi
          </div>
        </div>
      </div>
      <div class="cod-section__flow__content">
        <div
          class="cod-section__flow__content__wrap"
        >
          <div
            v-for="(flowData, index) of flowDatas"
            :key="flow + index"
            class="cod-section__flow__content__wrap__card"
          >
            <div class="cod-section__flow__content__wrap__card__head">
              <div class="cod-section__flow__content__wrap__card__head__no">
                <span>{{ flowData.no }}</span>
              </div>
              <div class="cod-section__flow__content__wrap__card__head__title">
                {{ flowData.title }}
              </div>
            </div>
            <div class="cod-section__flow__content__wrap__card__content">
              <ImageLazy
                :src="getImage(isMobileSize ? flowData.imgMobile: flowData.img)"
                :alt="`${flow}${index + 1}.png`"
                class="cod-section__flow__content__wrap__card__content__img"
                @error="(event) => isErrorImage(event, `${flow}${index + 1}`)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Flow -->

    <!-- FAQ -->
    <div class="cod-section__faq">
      <div class="cod-section__faq__left">
        <div class="cod-section__faq__left__bg-img">
          <ImageLazy
            :src="`/images/cod/ics_f_chat_question.svg`"
            alt="faq.png"
            class="cod-section__faq__left__bg-img__img"
            @error="(event) => isErrorImage(event, 'faq')"
          />
        </div>
        <div class="cod-section__faq__left__title">
          <span v-show="!isMobileSize">Terakhir, </span>Baca Yuk! Pertanyaan
          yang Sering Ditanyakan (FAQ)
        </div>
        <div
          v-show="!isMobileSize"
          id="cod-section__faq__left__button-wrapper"
        >
          <div class="cod-section__faq__left__desc">
            Masih bingung tentang COD?
          </div>
          <div class="cod-section__faq__left__button">
            <Button
              text="Tanya Liona, Yuk!"
              :img="`/cod/ics_f_conversation.svg`"
              type-class="red"
              :custom-padding="true"
              @click="triggerToCS()"
            />
          </div>
        </div>
      </div>
      <div class="cod-section__faq__right">
        <div
          v-for="(faq, index) in LIST_CONTENT.FAQ"
          id="issues"
          :key="index"
        >
          <CardCollapse
            :index="index"
            :sub-title="faq.question"
            :description="faq.answer"
            is-expand
            expand-disappear
            class="cod-section__faq__right__item-collapse"
          />
        </div>
        <div class="cod-section__faq__right__link">
          <span @click="goToFAQPage()">Lihat FAQ Selengkapnya</span>
          <ImageLazy
            :src="`/images/cod/ics_o_chevron_right.svg`"
            alt="chevron.png"
            width="18px"
            height="18px"
            @error="(event) => isErrorImage(event, 'chevron')"
          />
        </div>
        <div
          v-show="isMobileSize"
          id="cod-section__faq__right__button-wrapper"
        >
          <div class="cod-section__faq__right__desc">
            Masih bingung tentang COD?
          </div>
          <div class="cod-section__faq__right__button">
            <Button
              text="Tanya Liona, Yuk!"
              img="cod/ics_f_conversation.svg"
              type-class="red"
              :custom-padding="true"
              @click="triggerToCS()"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- FAQ -->

    <!-- Articles -->
    <EventAndTipsSection />
    <!-- Articles -->
  </div>
</template>

<script>
import BreadCrumb from "@/components/new-breadcrumb/BreadCrumb";
import Button from "../../components/new-button/Button";
import mixinMobile from "../../misc/mixinMobile";
import mixinMetaInfo from "../../misc/mixinMetaInfo";
import listPoint from "@/components/new-profit-and-registration/ProfitAndRegistrationContent";
import LIST_CONTENT from "./app/constants/list";
import ImageLazy from "@/components/ImageLazy/index.vue";
import { setImageDefault } from "@/components/ImageLazy/util";
import CardCollapse from "@/components/new-card-collapse";
import EventAndTipsSection from "./components/EventAndTipsSection.vue";

export default {
  name: "COD",
  components: {
    BreadCrumb,
    Button,
    listPoint,
    ImageLazy,
    CardCollapse,
    EventAndTipsSection,
  },
  mixins: [mixinMetaInfo, mixinMobile],
  data() {
    return {
      searchMetaInfo: "_cod", // from mixinMetaInfo, path tapi / dengan _ ex: /about jadi _about
      LIST_CONTENT,
      flow: "agent",
      flowDatas: LIST_CONTENT.AGENTFLOW,
    };
  },
  computed: {
    imgSrcBannerHeader() {
      return this.windowWidth <= 599
        ? '/images/cod/banner_mobile.webp'
        : '/images/cod/banner.webp';
    },
    informationGrouping() {
      const information = LIST_CONTENT.INFORMATION;
      return information.reduce(function(rows, key, index) {
        return (
          (index % 2 == 0
            ? rows.push([key])
            : rows[rows.length - 1].push(key)) && rows
        );
      }, []);
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.checkActiveButton();
  },
  methods: {
    goToFAQPage() {
      this.$router.push("/faq/bantuan/cash-on-delivery?page=1");
    },
    triggerToCS() {
      window.open("https://wa.me/6281119600999");
    },
    isErrorImage(event, addClass) {
      setImageDefault(event, addClass);
    },
    getImage(img) {
      return `images/${img}`;
    },
    changeFlow(val) {
      this.flow = val;
      this.flowDatas = val === 'agent' ? LIST_CONTENT.AGENTFLOW : LIST_CONTENT.APPFLOW
    },
    checkActiveButton() {
      const header = this.$el.querySelector("#button-tab");
      const btns = header.getElementsByClassName("cod-section__flow__head__btn-tab__button");
      for (let i = 0; i < btns.length; i++) {
        btns[i].addEventListener("click", function() {
          const current = document.getElementsByClassName("active");
          current[0].className = current[0].className.replace(" active", "");
          this.className += " active";
        });
      }
    },
  },
  metaInfo() {
    return this.SEO({
      channel: "channel",
      title: this.metaInfo.meta_info_title,
      description: this.metaInfo.meta_info_description,
      keywords: this.metaInfo.meta_info_keyword,
      publishedTime: this.metaInfo.meta_info_publish_time,
      image: this.metaInfo.attachment_meta_info_photo_url,
    });
  },
};
</script>

<style lang="scss" scoped>
@import "style.scss";
</style>
