/** @type {Array<InformationInfo>}*/
const INFORMATION = [
  {
    title: "Bayar Langsung di Tempat",
    desc: "Penerima bisa langsung bayar ditempat tanpa repot ke ATM/Bank.",
    img: "cod/ics_f_money_alt.svg",
  },
  {
    title: "Bisnis Makin Terpercaya",
    desc: "Pembayaran COD dapat meningkatkan kepercayaan pelanggan kamu.",
    img: "cod/ics_f_check_circle.svg",
  },
  {
    title: "Jangkauan Area yang Luas",
    desc: "Penerima bisa menikmati pengiriman ke seluruh Indonesia.",
    img: "cod/ics_f_maps.svg",
  },
  {
    title: "Anti Telat, Ongkir Diganti!",
    desc: "Pengiriman COD dijamin PASTI (Paket telat sehari PASTI ongkir diganti).",
    img: "cod/ics_f_money.svg",
  },
];

/** @type {Array<ProfitInfo>}*/
const PROFIT = [
  {
    title: "Tanpa Bayar Ongkir di Depan",
    desc: "Kamu bisa Pick Up melalui Aplikasi atau Drop Paket ke Agen Lion Parcel tanpa harus bayar ongkir.",
    img: "cod/ics_f_price_tag.svg",
  },
  {
    title: "Biaya COD yang Kompetitif",
    desc: "Biaya pengiriman yang murah, hemat dan bersaing dengan kompetitor lainnya.",
    img: "cod/ics_f_payment.svg",
  },
  {
    title: "Biaya Retur Hanya 50%",
    desc: "Biaya pengembalian paket sebesar 50% dari harga ongkir kota penerima ke kota pengirim.",
    img: "cod/ics_f_retur.svg",
  },
  {
    title: "Penarikan Dana Realtime",
    desc: "Dana COD dapat ditarik langsung dari saldo Aplikasi Lion Parcel setelah selesai diproses. (Khusus COD Ongkir & Barang)",
    img: "cod/ics_f_creditcard.svg",
  },
  {
    title: "Gratis Asuransi Pengiriman",
    desc: "Dapatkan asuransi untuk melindungi dari kerusakan, kehilangan, & keterlambatan. (Khusus COD Ongkir & Barang)",
    img: "cod/ics_f_insurance.svg",
  },
  {
    title: "Gratis Dashboard Pengiriman",
    desc: "Semakin mudah mengelola banyak paket & menganalisa data pengiriman kamu.",
    img: "cod/ics_f_grid.svg",
  },
];

/** @type {Array<FAQInfo>}*/
const FAQ = [
  {
    question: "Apa itu COD <i>(Cash on Delivery)</i>?",
    answer: "<strong>COD <i>(Cash on Delivery)</i></strong> adalah metode pembayaran pengiriman barang dengan cara tunai ketika barang diterima oleh penerima.<br><br>Lion Parcel memiliki 2 jenis metode pembayaran COD:<ol><li><strong>COD Ongkir:</strong> COD yang penerimanya hanya membayar <strong>Biaya Ongkir</strong> saja.</li><li><strong>COD Ongkir & Barang:</strong> COD yang penerimanya harus membayar <strong>Biaya Ongkir ditambah Harga Barang</strong> yang dikirimkan.</li></ol>",
  },
  {
    question: "Jenis pengiriman apa saja yang bisa menggunakan metode pembayaran COD <i>(Cash on Delivery)</i>?",
    answer: "Saat ini hanya berlaku untuk jenis pengiriman <strong>REGPACK.</strong>",
  },
  {
    question: "Layanan dengan metode pembayaran COD <i>(Cash on Delivery)</i> tersedia di kota mana saja?",
    answer: "Layanan COD saat ini tersedia di seluruh Indonesia.",
  },
  {
    question: "Bagaimana cara melakukan pengiriman dengan metode pembayaran COD <i>(Cash on Delivery)</i>?",
    answer: "Kamu bisa mengirim barang dengan <strong>COD Ongkir atau COD Ongkir & Barang melalui Aplikasi/Agen Lion Parcel.</strong><br><br><ul><li><strong>COD Ongkir</strong><ol><li><strong>Kamu tinggal Pick Up/Drop Paket</strong> melalui Aplikasi/Agen Lion Parcel Terdekat.</li><li>Pilih layanan <strong>COD Ongkir</strong> di Aplikasi/Agen Lion Parcel.</li><li><strong>Agen Lion Parcel</strong> akan Memproses Paket.</li><li>Paket akan diantarkan kurir ke penerima.</li><li>Setelah barang sampai, penerima harus membayar tagihan COD sejumlah <strong>Biaya Ongkir + Biaya Tambahan COD sebesar minimal Rp1.000 atau 3% dari total Biaya Ongkir.</strong></li></ol></li><br><li><strong>COD Ongkir & Barang</strong><ol><li><strong>Kamu tinggal Pick Up/Drop Paket</strong> melalui Aplikasi/Agen Lion Parcel Terdekat.</li><li>Pilih layanan <strong>COD Ongkir & Barang</strong> di Aplikasi/Agen Lion Parcel.</li><li>Pastikan <strong>nomor handphone kamu terdaftar di Aplikasi Lion Parcel.</strong></li><li>Pastikan harga barang yang dikirim <strong>seharga Rp10.000 - Rp5.000.000.</strong></li><li><strong>Agen Lion Parcel</strong> akan memproses Paket.</li><li>Setelah barang sampai, penerima harus membayar tagihan COD sejumlah <strong>Biaya Ongkir + Harga Barang.</strong></li><li>Pengirim akan dikenakan <strong>Biaya Tambahan COD minimal Rp4.000 atau 4% dari total (Harga Barang + Biaya Ongkir untuk COD Barang).</strong></li><li>Pembayaran biaya tambahan COD akan <strong>otomatis dipotong dari Penghasilan COD pengirim.</strong></li><li>Penghasilan COD akan dikirim ke <strong>saldo Aplikasi Lion Parcel.</strong></li></ol></li></ul>",
  },
  {
    question: "Apakah COD <i>(Cash on Delivery)</i> memiliki asuransi?",
    answer: "<ul><li><strong>Asuransi COD Ongkir</strong><ol><li>Untuk jenis COD ini, pengirim dapat memilih memakai <strong>asuransi dengan Biaya Tambahan:</strong></li><ol style='list-style-type: lower-alpha'><li>Jika <strong>Harga Barang di bawah Rp5.000.000</strong> pemakaian asuransi bersifat <strong>opsional.</strong></li><li>Jika <strong>Harga Barang sama dengan atau di atas Rp5.000.000</strong> maka <strong>asuransi diwajibkan dengan membayar biaya sebesar 0.4% dari Harga Barang.</strong></li></ol><li>Jika terjadi keterlambatan maka mengikuti <strong>ketentuan dari PASTI (Paket telat sehari PASTI ongkir diganti).</strong> Karena menggunakan <strong>layanan REGPACK.</strong></li></ol></li><br><li><strong>Asuransi COD Ongkir & Barang</strong><ol><li>Untuk <strong>jenis COD</strong> ini, pengirim akan mendapatkan <strong>asuransi secara gratis.</strong> Jika terjadi kehilangan atau kerusakan saat pengiriman, pengirim <strong>akan mendapatkan total penggantian penuh saat klaim sesuai dengan Harga Barang yang di masukkan, tanpa adanya potongan untuk biaya pengiriman.</strong></li><li>Jika terjadi keterlambatan maka mengikuti <strong>ketentuan dari PASTI (Paket telat sehari PASTI ongkir diganti).</strong> Karena menggunakan <strong>layanan REGPACK.</strong></li></ol></li></ul>",
  },
  {
    question: "Bagaimana cara penarikan Penghasilan COD <i>(Cash on Delivery)</i> Ongkir & Barang?",
    answer: "<strong>Khusus COD Ongkir + Barang</strong> kamu bisa menarik <strong>Penghasilan COD</strong> dengan cara:<ol><li>Pastikan <strong>nomor handphone kamu terdaftar di Aplikasi Lion Parcel.</strong></li><li>Pastikan <strong>paket pengiriman kamu telah sampai ke penerima.</strong></li><li>Setelah penerima membayar COD, <strong>penghasilan COD akan tersedia di saldo Aplikasi Lion Parcel.</strong></li><li>Penghasilan COD yang didapatkan setelah penerima membayar COD, dapat <strong>ditarik secara langsung</strong> dari <strong>saldo Aplikasi Lion Parcel</strong> ketika statusnya <strong>“Penghasilan COD berhasil diproses”.</strong></li><li>Penghasilan COD dapat ditarik ke <strong>akun rekening Bank ataupun E-wallet kamu.</strong></li></ol>",
  },
];

/** @type {Array<AgenFlowInfo>}*/
const AGENTFLOW = [
  {
    no: "1",
    title: "Antar Paket ke Agen Lion Parcel Terdekat",
    img: "cod/antar-paketcod-desktop.webp",
    imgMobile: "cod/antar-paketcod-m.svg",
  },
  {
    no: "2",
    title: "Pilih Layanan COD di Agen Lion Parcel",
    img: "cod/pilih-layanan-cod-desktop.webp",
    imgMobile: "cod/pilih-layanan-cod-m.svg",
  },
  {
    no: "3",
    title: "Paket akan Diantarkan Kurir ke Penerima",
    img: "cod/diantarkan-kurir-desktop.webp",
    imgMobile: "cod/diantarkan-kurir-m.svg",
  },
  {
    no: "4",
    title: "Penerima Bayar Tagihan COD Saat Paket Sampai",
    img: "cod/bayar-cod-desktop.webp",
    imgMobile: "cod/bayar-cod-m.svg",
  },
];

/** @type {Array<AppFlowInfo>}*/
const APPFLOW = [
  {
    no: "1",
    title: "Klik Menu Pick Up & Isi Detail Pengiriman",
    img: "cod/pickup-desktop.webp",
    imgMobile: "cod/pickup-m.svg",
  },
  {
    no: "2",
    title: "Pilih Layanan COD & Klik “COD Sekarang”",
    img: "cod/cod-desktop.webp",
    imgMobile: "cod/cod-m.svg",
  },
  {
    no: "3",
    title: "Kurir Jemput Paket Kamu & Antar ke Penerima",
    img: "cod/diantarkan-kurir-desktop.webp",
    imgMobile: "cod/diantarkan-kurir-m.svg",
  },
  {
    no: "4",
    title: "Penerima Bayar Tagihan COD Saat Paket Sampai",
    img: "cod/bayar-cod-desktop.webp",
    imgMobile: "cod/bayar-cod-m.svg",
  },
];

module.exports = { INFORMATION, PROFIT, FAQ, AGENTFLOW, APPFLOW };
